export const environment = {
  production: true,
  name: 'prod',
  apiUrl: 'https://jarvis-lla.com/api/v1',
  websiteUrl: 'https://jarvis-lla.com',
  googlePlacesKey: 'AIzaSyBbX53NraTR61QpCQN59l0YWZw-_ts6Vrk',
  googleAnalyticsToken: 'G-K25X3VK2HV',
  pageSize: 100,
  dashboardWidgetsList: {
    top: 10,
    bottom: 3
  },
  jwtSecret: 'simple_jwt_secret'
}
