<div class="md:hidden mb-base flex items-center">
  <div class="flex-1 text-base font-semibold leading-base">{{ label }}</div>
  <div class="flex text-txt-secondary cursor-pointer" (click)="canceld.emit()">
    <svg-icon key="close" class="!text-2xs"></svg-icon>
  </div>
</div>
<form [formGroup]="form">
  <lla-input
    class="block"
    [autoFocus]="true"
    [normalInputId]="searchInputId"
    *ngIf="searchable && allItems.length > 6"
    controlName="searchText"
    type="text"
    placeholder="Search"
    icon="search"
    size="smaller"
    [clearable]="true"
  ></lla-input>
</form>

<ng-content></ng-content>

<div class="{{ selectorClass }}">
  <ng-container *ngFor="let item of selectedItems">
    @let disableItem = getDisableItem(item.value);
    <div
      class="py-xs px-sm text-sm leading-sm text-txt hover:bg-ui-hover cursor-pointer"
      [ngClass]="{
        '!bg-primary hover:!bg-primary !text-white': selectedId === item.value,
        '!text-gray-400': disableItem && selectedId !== item.value
      }"
      (click)="
        disableItem ? undefined : selectedIdChanged.emit(item.value);
        this.form.get('searchText')?.reset()
      "
    >
      {{ item.label }}{{ disableItem?.reason ? ' - ' + disableItem?.reason : '' }}
    </div>
  </ng-container>
</div>
